/* eslint-env browser */

const cache = new WeakMap<Element, RiveInstance>();
const callbacks = new WeakMap<Element, (rive: any) => void>();
const loadEvent = new Event('w-rive-load');

const getRiveLibrary = (win: Window) => win.Webflow.require('rive').rive;

interface RiveLoadProps {
  container: HTMLElement;
  src: string;
  stateMachine?: string;
  artboard?: string;
  onLoad?: () => void;
  autoplay?: boolean;
  isTouchScrollEnabled?: boolean;
  automaticallyHandleEvents?: boolean;
  fit?: string;
  alignment?: string;
}

class RiveInstance {
  rive: any | null = null;
  container: HTMLElement | null = null;
  riveInstanceSuccessLoaded: boolean | null = null;
  riveInstanceErrorLoaded: boolean | null = null;

  // To avoid memory garbage, the first rive instance is not removed from memory.
  // see more here: https://rive.app/community/doc/rive-parameters/docHI9ASztXP#cleanup
  cleanMemoryGarbage() {
    try {
      if (this.rive && this.riveInstanceSuccessLoaded) {
        this.rive.removeAllRiveEventListeners();
        this.rive.cleanup();
        this.riveInstanceSuccessLoaded = null;
        this.rive = null;
      }
    } catch (e) {
      console.error('Error cleaning up Rive instance:', e);
    }
  }

  destroy() {
    this.cleanMemoryGarbage();
    if (this.container) {
      cache.delete(this.container);
      callbacks.delete(this.container);
    }
  }

  async load({
    container,
    src,
    stateMachine,
    artboard,
    onLoad,
    autoplay = false,
    isTouchScrollEnabled = false,
    automaticallyHandleEvents = false,
    fit,
    alignment,
  }: RiveLoadProps) {
    try {
      this.riveInstanceSuccessLoaded = false;
      const win = container.ownerDocument.defaultView as Window;
      const canvas = container.querySelector('canvas');
      const RiveLib = getRiveLibrary(win);
      const layout = new RiveLib.Layout({
        fit: fit ?? RiveLib.Fit.Contain,
        alignment: alignment ?? RiveLib.Alignment.Center,
      });

      const riveProps = {
        artboard,
        layout,
        autoplay,
        isTouchScrollEnabled,
        automaticallyHandleEvents,
        src,
        stateMachines: stateMachine,
        onLoad: () => {
          this.riveInstanceSuccessLoaded = true;
          this.riveInstanceErrorLoaded = false;

          this.rive.resizeDrawingSurfaceToCanvas();
          onLoad?.();
        },
        onLoadError: () => {
          if (!this.riveInstanceErrorLoaded) {
            this.rive.load({
              ...riveProps,
              artboard: undefined,
              stateMachines: undefined,
            });
          }
          this.riveInstanceErrorLoaded = true;
          this.riveInstanceSuccessLoaded = false;
        },
      };

      if (this.rive && this.rive?.source === src) {
        this.rive.load(riveProps);
      } else {
        this.cleanMemoryGarbage();
        const rive = new RiveLib.Rive({...riveProps, canvas});

        cache.set(container, this);
        this.container = container;
        this.rive = rive;
        container.dispatchEvent(loadEvent);

        if (callbacks.has(container)) {
          callbacks.get(container)?.(rive);
          callbacks.delete(container);
        }
      }
    } catch (e) {
      this.riveInstanceSuccessLoaded = false;
      console.error('Error loading Rive instance:', e);
    }
  }
}

const getRiveElements = (): HTMLElement[] =>
  Array.from(document.querySelectorAll('[data-animation-type="rive"]'));

export const createInstance = async ({
  container,
  onLoad,
  src,
  stateMachine,
  artboard,
  fit,
  alignment,
  autoplay = true,
  isTouchScrollEnabled = false,
  automaticallyHandleEvents = false,
}: RiveLoadProps): Promise<RiveInstance> => {
  let riveInstance = cache.get(container);

  if (riveInstance == null) {
    riveInstance = new RiveInstance();
  }
  await riveInstance.load({
    container,
    src,
    stateMachine,
    artboard,
    onLoad,
    autoplay,
    isTouchScrollEnabled,
    automaticallyHandleEvents,
    fit,
    alignment,
  });
  return riveInstance;
};

export const destroyInstance = (element: HTMLElement) => {
  const riveInstance = cache.get(element);
  riveInstance?.destroy();
  cache.delete(element);
};

export const getInstance = (element: HTMLElement): RiveInstance | undefined => {
  return cache.get(element);
};

export const setLoadHandler = (
  element: HTMLElement,
  callback: (rive: any) => void
) => {
  if (element) callbacks.set(element, callback);
};

export const init = () => {
  getRiveElements().forEach((element) => {
    const src = element.getAttribute('data-rive-url');
    const stateMachine =
      element.getAttribute('data-rive-state-machine') ?? undefined;
    const artboard = element.getAttribute('data-rive-artboard') ?? undefined;
    const fit = element.getAttribute('data-rive-fit') ?? undefined;
    const alignment = element.getAttribute('data-rive-alignment') ?? undefined;
    const autoPlay = element.getAttribute('data-rive-autoplay');
    const isTouchscrollEnabled = element.getAttribute(
      'data-rive-is-touch-scroll-enabled'
    );
    const automaticallyHandleEvents = element.getAttribute(
      'data-rive-automatically-handle-events'
    );

    if (src) {
      createInstance({
        container: element,
        src,
        stateMachine,
        artboard,
        fit,
        alignment,
        autoplay: autoPlay === 'true',
        isTouchScrollEnabled: isTouchscrollEnabled === 'true',
        automaticallyHandleEvents: automaticallyHandleEvents === 'true',
      });
    }
  });
};

export const destroy = () => {
  getRiveElements().forEach(destroyInstance);
};

export const ready = init;
